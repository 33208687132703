import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ChevronDownIcon, PhoneIcon, Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { navigation } from '../../constants/navigation';

const Header = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [openDropdowns, setOpenDropdowns] = useState({});

  const toggleDropdown = (name) => {
    setOpenDropdowns(prev => ({ ...prev, [name]: !prev[name] }));
  };

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
    document.body.style.overflow = showMobileMenu ? 'auto' : 'hidden';
  };

  return (
    <header className="bg-white shadow-md sticky top-0 z-50">
      <nav className="container mx-auto px-4 sm:px-6 lg:px-8 flex items-center justify-between h-16">
        <div className="flex items-center">
          <Link to="/" className="flex-shrink-0">
            <img className="h-12 w-12 rounded-full object-cover" src="/assets/images/376766933_803696125088836_2868455817913052847_n.jpg" alt="McEwen Logging & Excavation" />
          </Link>
        </div>

        {/* Desktop Navigation */}
        <div className="hidden lg:block ml-10">
          <div className="flex space-x-4">
            {navigation.map((item) => (
              <div key={item.name} className="relative group">
                {item.children ? (
                  <>
                    <button
                      className="text-gray-800 hover:bg-gray-100 px-3 py-2 rounded-md text-sm font-medium flex items-center"
                    >
                      {item.name}
                      <ChevronDownIcon className="ml-1 h-4 w-4" aria-hidden="true" />
                    </button>
                    <div className="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300 ease-in-out">
                      <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        {item.children.map((subItem) => (
                          <Link
                            key={subItem.name}
                            to={subItem.href}
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            role="menuitem"
                          >
                            {subItem.name}
                          </Link>
                        ))}
                      </div>
                    </div>
                  </>
                ) : (
                  <Link
                    to={item.href}
                    className="text-gray-800 hover:bg-gray-100 px-3 py-2 rounded-md text-sm font-medium"
                  >
                    {item.name}
                  </Link>
                )}
              </div>
            ))}
          </div>
        </div>

        {/* Mobile Navigation Button */}
        <div className="lg:hidden">
          <button
            onClick={toggleMobileMenu}
            className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-mcewen-green"
          >
            <span className="sr-only">Open main menu</span>
            {showMobileMenu ? (
              <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
            ) : (
              <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
            )}
          </button>
        </div>

        {/* Mobile Navigation Menu */}
        <div className={`lg:hidden fixed inset-0 bg-white z-50 transition-transform duration-300 ease-in-out ${showMobileMenu ? 'translate-x-0' : 'translate-x-full'}`}>
          <div className="flex justify-between items-center p-4 border-b">
            <Link to="/" className="flex-shrink-0" onClick={toggleMobileMenu}>
              <img className="h-12 w-12 rounded-full object-cover" src="/assets/images/376766933_803696125088836_2868455817913052847_n.jpg" alt="McEwen Logging & Excavation" />
            </Link>
            <button
              onClick={toggleMobileMenu}
              className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-mcewen-green"
            >
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="px-2 pt-2 pb-3 space-y-1 overflow-y-auto h-full">
            {navigation.map((item) => (
              <div key={item.name} className="py-2">
                {item.children ? (
                  <>
                    <button
                      onClick={() => toggleDropdown(item.name)}
                      className="w-full text-left flex justify-between items-center px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                      <ChevronDownIcon className={`h-5 w-5 transform ${openDropdowns[item.name] ? 'rotate-180' : ''} transition-transform duration-200`} />
                    </button>
                    {openDropdowns[item.name] && (
                      <div className="pl-4 mt-2 space-y-2">
                        {item.children.map((subItem) => (
                          <Link
                            key={subItem.name}
                            to={subItem.href}
                            className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                            onClick={toggleMobileMenu}
                          >
                            {subItem.name}
                          </Link>
                        ))}
                      </div>
                    )}
                  </>
                ) : (
                  <Link
                    to={item.href}
                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                    onClick={toggleMobileMenu}
                  >
                    {item.name}
                  </Link>
                )}
              </div>
            ))}
          </div>
        </div>

        <div className="hidden lg:flex items-center">
          <a href="tel:+11234567890" className="text-gray-800 hover:text-mcewen-green flex items-center mr-4">
            <PhoneIcon className="h-5 w-5 mr-1" />
            (615) 586-0720
          </a>
          <Link
            to="/contact"
            className="bg-mcewen-yellow text-mcewen-green hover:bg-yellow-400 px-4 py-2 rounded-md text-sm font-medium"
          >
            Contact
          </Link>
        </div>
      </nav>
    </header>
  );
};

export default Header;