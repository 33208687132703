import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-mcewen-green text-white">
      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <h3 className="text-lg font-semibold mb-4">McEwen Logging & Excavation</h3>
            <p>Providing quality logging and excavation services since 2005</p>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
            <ul>
              <li><Link to="/services" className="hover:text-mcewen-yellow">Services</Link></li>
              <li><Link to="/projects" className="hover:text-mcewen-yellow">Projects</Link></li>
              <li><Link to="/about" className="hover:text-mcewen-yellow">About Us</Link></li>
              <li><Link to="/contact" className="hover:text-mcewen-yellow">Contact</Link></li>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Contact Us</h3>
            <p>5414 Highway 49 W</p>
            <p>Vanleer, TN 37181</p>
            <p>Phone: (615) 586-0720</p>
            <p>Email: mcewenexcavation@gmail.com</p>
          </div>
        </div>
        <div className="mt-8 border-t border-gray-700 pt-8 text-center">
          <p>&copy; {new Date().getFullYear()} McEwen Logging & Excavation. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
