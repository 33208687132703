export const navigation = [
  { name: 'Home', href: '/' },
  {
    name: 'Services',
    href: '/services',
    children: [
      { name: 'All Services', href: '/services' },
      { name: 'Excavation', href: '/services/excavation' },
      { name: 'Land Clearing', href: '/services/land-clearing' },
      { name: 'Logging', href: '/services/logging' },
      { name: 'Road Building', href: '/services/road-building' },
      { name: 'Site Preparation', href: '/services/site-preparation' },
      { name: 'Trucking', href: '/services/trucking' },
    ],
  },
  { name: 'Projects', href: '/projects' },
  { name: 'About Us', href: '/about' },
  { name: 'Contact', href: '/contact' },
];
