import React, { useEffect } from 'react';
import AppRouter from './AppRouter';

function App() {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.text = JSON.stringify({
      "@context": "https://schema.org",
      "@type": "LocalBusiness",
      "name": "McEwen Logging and Excavation",
      "image": "https://www.mcewenloggingexcavation.com/logo.png",
      "description": "Professional logging, excavation, and land services in Nashville, TN and surrounding areas within a 200-mile radius.",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "123 Main St",
        "addressLocality": "Nashville",
        "addressRegion": "TN",
        "postalCode": "37201",
        "addressCountry": "US"
      },
      "geo": {
        "@type": "GeoCoordinates",
        "latitude": 36.1627,
        "longitude": -86.7816
      },
      "url": "https://www.mcewenloggingexcavation.com",
      "telephone": "+1-615-555-0123",
      "openingHoursSpecification": [
        {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday"
          ],
          "opens": "07:00",
          "closes": "18:00"
        }
      ],
      "sameAs": [
        "https://www.facebook.com/McEwenLoggingExcavation",
        "https://www.linkedin.com/company/mcewen-logging-excavation"
      ]
    });
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div className="App">
      <AppRouter />
    </div>
  );
}

export default App;
