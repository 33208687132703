import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';

const Home = lazy(() => import('./pages/Home'));
const OurWork = lazy(() => import('./pages/OurWork'));
const Projects = lazy(() => import('./pages/Projects'));
const Services = lazy(() => import('./pages/Services'));
const LandClearing = lazy(() => import('./pages/services/land-clearing/LandClearing'));
const RoadBuilding = lazy(() => import('./pages/services/road-building/RoadBuilding'));
const Logging = lazy(() => import('./pages/services/logging/Logging'));
const Excavation = lazy(() => import('./pages/services/excavation/Excavation'));
const SitePreparation = lazy(() => import('./pages/services/site-preparation/SitePreparation'));
const Trucking = lazy(() => import('./pages/services/trucking/Trucking'));
const About = lazy(() => import('./pages/About'));
const Careers = lazy(() => import('./pages/Careers'));
const Contact = lazy(() => import('./pages/Contact'));
const Blog = lazy(() => import('./components/Blog'));
const BlogPost = lazy(() => import('./pages/BlogPost'));

const AppRouter = () => {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Header />
        <main className="flex-grow">
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/our-work" element={<OurWork />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/services" element={<Services />} />
              <Route path="/services/land-clearing" element={<LandClearing />} />
              <Route path="/services/road-building" element={<RoadBuilding />} />
              <Route path="/services/logging" element={<Logging />} />
              <Route path="/services/excavation" element={<Excavation />} />
              <Route path="/services/site-preparation" element={<SitePreparation />} />
              <Route path="/services/trucking" element={<Trucking />} />
              <Route path="/about-us" element={<About />} />
              <Route path="/careers" element={<Careers />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/blog/:slug" element={<BlogPost />} />
            </Routes>
          </Suspense>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default AppRouter;
